import React, { useState } from "react"
import { useAuth } from "../context/AuthContext"
import {
  FaExchangeAlt,
  FaTimesCircle,
  FaQuestionCircle,
  FaTwitter,
} from "react-icons/fa"
import { sendAlert } from "../utils/sendalert"

const BillingOption = ({ title, onClick, icon: Icon }) => (
  <div
    className="bg-neutral-800 border border-neutral-700 shadow-md rounded-lg p-8 cursor-pointer hover:bg-neutral-700 transition duration-300 flex flex-col items-center justify-center md:h-64"
    onClick={onClick}
  >
    <h3 className="text-2xl font-semibold mb-6 text-white">{title}</h3>
    <Icon className="text-6xl text-neutral-300" />
  </div>
)

function Billing() {
  const { user } = useAuth()
  const [showSupportMenu, setShowSupportMenu] = useState(false)
  const [email, setEmail] = useState(user?.email || "")
  const [message, setMessage] = useState("")
  const [messageSubmitted, setMessageSubmitted] = useState(false)
  const [cancelReason, setCancelReason] = useState("")
  const [otherReason, setOtherReason] = useState("")
  const [intermediateResponse, setIntermediateResponse] = useState("")
  const [preventionFeedback, setPreventionFeedback] = useState("")
  const [showError, setShowError] = useState(false)
  const [currentStep, setCurrentStep] = useState("initial")

  const handleChangePlan = () => {
    window.location.href =
      "https://billing.stripe.com/p/login/bIYdTP4ih4Jxfqo9AA"
  }

  const handleCancelPlan = () => {
    setCurrentStep("cancel-reason")
  }

  const handleCancelSubscription = () => {
    if (isReasonSelected) {
      setCurrentStep(
        [
          "features",
          "alternative",
          "customer-service",
          "ease-of-use",
          "quality",
        ].includes(cancelReason)
          ? cancelReason
          : "final-question"
      )
      setShowError(false)
    } else {
      setShowError(true)
    }
  }

  const handleIntermediateSubmit = () => {
    if (intermediateResponse.trim() === "") {
      setShowError(true)
    } else {
      setShowError(false)
      setCurrentStep("final-question")
    }
  }

  const handleFinalSubmit = async () => {
    if (preventionFeedback.trim() === "") {
      setShowError(true)
      return
    }

    const cancellationInfo = {
      email: user?.email || email, // Use user's email if available, otherwise use the email from state
      reason: cancelReason,
      otherReason: cancelReason === "other" ? otherReason : "",
      intermediateResponse,
      preventionFeedback,
    }

    try {
      await sendAlert(`Cancellation request:
Email: ${cancellationInfo.email}
Reason: ${cancellationInfo.reason}
${
  cancellationInfo.otherReason
    ? `Other reason: ${cancellationInfo.otherReason}`
    : ""
}
Intermediate response: ${cancellationInfo.intermediateResponse}
Prevention feedback: ${cancellationInfo.preventionFeedback}`)
    } catch (error) {
      console.error("Error sending cancellation info to Telegram:", error)
    }

    window.location.href =
      "https://billing.stripe.com/p/login/bIYdTP4ih4Jxfqo9AA"
  }

  const handleNeedSupport = () => {
    setShowSupportMenu(true)
    setCurrentStep("support")
  }

  const handleDMOnX = () => {
    window.open(
      "https://twitter.com/messages/compose?recipient_id=1611660808835964928",
      "_blank"
    )
  }

  const handleSendMessage = async (e) => {
    e.preventDefault()
    try {
      await sendAlert(`Support request:
Email: ${email}
Message: ${message}`)
    } catch (error) {
      console.error("Error sending support message to Telegram:", error)
    }

    setEmail("")
    setMessage("")
    setMessageSubmitted(true)
    setTimeout(() => {
      setMessageSubmitted(false)
      setShowSupportMenu(false)
    }, 3000)
  }

  const isReasonSelected =
    cancelReason !== "" &&
    (cancelReason !== "other" || otherReason.trim() !== "")

  const renderStep = () => {
    switch (currentStep) {
      case "initial":
        return (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <BillingOption
              title="Change plan"
              onClick={handleChangePlan}
              icon={FaExchangeAlt}
            />
            <BillingOption
              title="Cancel plan"
              onClick={handleCancelPlan}
              icon={FaTimesCircle}
            />
            <BillingOption
              title="Need support"
              onClick={handleNeedSupport}
              icon={FaQuestionCircle}
            />
          </div>
        )
      case "support":
        return (
          <div className="bg-neutral-800 border border-neutral-700 shadow-md rounded-lg p-8">
            <h3 className="text-xl font-semibold text-white mb-4">
              How can I help you?
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <BillingOption
                title="DM me on X"
                onClick={handleDMOnX}
                icon={FaTwitter}
              />
              <div className="bg-neutral-800 border border-neutral-700 shadow-md rounded-lg p-8">
                <h3 className="text-2xl font-semibold mb-6 text-white text-center">
                  Leave a Message
                </h3>
                {!messageSubmitted ? (
                  <form onSubmit={handleSendMessage}>
                    {user ? (
                      <input
                        type="email"
                        value={user.email}
                        readOnly
                        className="w-full p-2 mb-4 bg-neutral-700 text-white rounded"
                      />
                    ) : (
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Your email"
                        className="w-full p-2 mb-4 bg-neutral-700 text-white rounded"
                        required
                      />
                    )}
                    <textarea
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Your message"
                      className="w-full p-2 mb-4 bg-neutral-700 text-white rounded h-32"
                      required
                    />
                    <button
                      type="submit"
                      className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
                    >
                      Send
                    </button>
                  </form>
                ) : (
                  <div className="text-green-500 text-xl font-semibold text-center">
                    Message submitted!
                  </div>
                )}
              </div>
            </div>
            <button
              onClick={() => {
                setShowSupportMenu(false)
                setCurrentStep("initial")
              }}
              className="mt-6 bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-700 transition duration-300"
            >
              Back
            </button>
          </div>
        )
      case "cancel-reason":
        return (
          <div className="bg-neutral-800 border border-neutral-700 shadow-md rounded-lg p-8">
            <div className="flex flex-col items-center mb-6">
              <span className="text-6xl">😢</span>
              <h2 className="text-2xl font-semibold text-white text-center">
                I am sad to see you go...
              </h2>
              <p className="text-neutral-300 text-center mt-2">
                Please share why you're leaving. Your feedback helps me improve
                the product!
              </p>
            </div>
            <div className="space-y-2">
              {[
                { value: "expensive", label: "It's too expensive" },
                { value: "features", label: "I need more features" },
                { value: "alternative", label: "I found an alternative" },
                { value: "no-need", label: "I no longer need it" },
                {
                  value: "customer-service",
                  label: "Customer service was less than expected",
                },
                {
                  value: "ease-of-use",
                  label: "Ease of use was less than expected",
                },
                { value: "quality", label: "Quality was less than expected" },
                { value: "other", label: "Other reason" },
              ].map((reason) => (
                <div key={reason.value} className="flex items-center">
                  <input
                    type="radio"
                    id={reason.value}
                    name="cancelReason"
                    value={reason.value}
                    checked={cancelReason === reason.value}
                    onChange={(e) => setCancelReason(e.target.value)}
                    className="mr-2"
                  />
                  <label htmlFor={reason.value} className="text-white">
                    {reason.label}
                  </label>
                </div>
              ))}
            </div>
            {cancelReason === "other" && (
              <textarea
                value={otherReason}
                onChange={(e) => setOtherReason(e.target.value)}
                placeholder="Please specify your reason"
                className="w-full p-2 mt-4 mb-4 bg-neutral-700 text-white rounded h-32"
              />
            )}
            <div className="flex justify-between mt-6">
              <button
                onClick={() => {
                  setCurrentStep("initial")
                }}
                className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-700 transition duration-300"
              >
                Nevermind, I'll stay
              </button>
              <button
                onClick={handleCancelSubscription}
                className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
              >
                Cancel Subscription
              </button>
            </div>
            {showError && (
              <p className="text-red-500 mt-2 text-center">
                Please select a reason for cancellation to proceed.
              </p>
            )}
          </div>
        )
      case "features":
      case "alternative":
      case "ease-of-use":
      case "quality":
        return (
          <div className="bg-neutral-800 border border-neutral-700 shadow-md rounded-lg p-8">
            <h3 className="text-xl font-semibold text-white mb-4">
              {currentStep === "features" && "Which features were you missing?"}
              {currentStep === "alternative" &&
                "Which alternative did you find?"}
              {currentStep === "ease-of-use" &&
                "How could I improve the ease of use?"}
              {currentStep === "quality" &&
                "In which areas did you find the quality lacking?"}
            </h3>
            <textarea
              value={intermediateResponse}
              onChange={(e) => setIntermediateResponse(e.target.value)}
              placeholder="Please provide your feedback"
              className="w-full p-2 mb-4 bg-neutral-700 text-white rounded h-32"
            />
            <div className="flex justify-between mt-6">
              <button
                onClick={() => setCurrentStep("cancel-reason")}
                className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-700 transition duration-300"
              >
                Back
              </button>
              <button
                onClick={handleIntermediateSubmit}
                className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
              >
                Cancel Subscription
              </button>
            </div>
            {showError && (
              <p className="text-red-500 mt-2 text-center">
                Please provide feedback to proceed with cancellation.
              </p>
            )}
          </div>
        )
      case "customer-service":
        return (
          <div className="bg-neutral-800 border border-neutral-700 shadow-md rounded-lg p-8">
            <h3 className="text-xl font-semibold text-white mb-4">
              Would you like to contact support before cancelling?
            </h3>
            <div className="flex justify-between mt-6">
              <button
                onClick={() => setCurrentStep("cancel-reason")}
                className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-700 transition duration-300"
              >
                Back
              </button>
              <button
                onClick={() => setCurrentStep("final-question")}
                className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
              >
                Cancel Subscription
              </button>
            </div>
            {showError && (
              <p className="text-red-500 mt-2 text-center">
                Please select a support option or proceed with cancellation.
              </p>
            )}
          </div>
        )
      case "final-question":
        return (
          <div className="bg-neutral-800 border border-neutral-700 shadow-md rounded-lg p-8">
            <h3 className="text-xl font-semibold text-white mb-4">
              What is something we could have done to prevent you from leaving?
            </h3>
            <textarea
              value={preventionFeedback}
              onChange={(e) => setPreventionFeedback(e.target.value)}
              placeholder="Your feedback is valuable to us"
              className="w-full p-2 mb-4 bg-neutral-700 text-white rounded h-32"
            />
            <div className="flex justify-between mt-6">
              <button
                onClick={() => setCurrentStep("cancel-reason")}
                className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-700 transition duration-300"
              >
                Back
              </button>
              <button
                onClick={handleFinalSubmit}
                className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
              >
                Cancel Subscription
              </button>
            </div>
            {showError && (
              <p className="text-red-500 mt-2 text-center">
                Please provide feedback before finalizing cancellation.
              </p>
            )}
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className="container mx-auto px-4 py-8 bg-neutral-900 min-h-screen">
      <h1 className="text-3xl font-bold mb-8 text-white">
        Billing -{" "}
        {currentStep === "initial"
          ? "Options"
          : currentStep === "support"
          ? "Support"
          : "Cancel"}
      </h1>
      {renderStep()}
    </div>
  )
}

export default Billing
