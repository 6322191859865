import React, { useEffect, useState } from "react"
import { useAuth } from "../../context/AuthContext"
import { firestore, serverTimestamp } from "../../firebase"
import { sendAlert } from "../../utils/sendalert"
import { useHistory } from "react-router-dom"

export default function Pattern({
  setCreateImageFunction,
  creditCost,
  setCreditCost,
}) {
  const history = useHistory()
  const { user } = useAuth()

  const [error, setError] = useState(false)
  const [prompt, setPrompt] = useState("")

  useEffect(() => {
    setCreditCost(10)
  }, [setCreditCost])

  const createImageDocument = async () => {
    try {
      setError(null)
      if (!user) {
        sendAlert(
          "Create.js, createImageDocument, user not found!, user: " + user
        )
        setError("Register to get tokens.")
        return
      }

      if (user.balance <= 0 || user.balance < creditCost) {
        setError("Not enough tokens!")
        history.push("/pricing")
        return
      }

      const credits = user.balance - creditCost
      try {
        await firestore.collection("users").doc(user.id).update({
          balance: credits,
        })
      } catch (error) {
        sendAlert(
          "Create, createImageDocument, pattern, error: " + error.message
        )
        setError("Error while updating tokens!")
        return null
      }
      const imageData = {
        userId: user.id,
        createdAt: serverTimestamp(),
        status: "created",
        mode: "pattern-create",
        prompt: prompt,
        creditCost: creditCost,
      }

      try {
        const imagesCollectionRef = firestore
          .collection("users")
          .doc(user.id)
          .collection("images")

        const docRef = await imagesCollectionRef.add(imageData)
        return docRef.id
      } catch (error) {
        sendAlert("Create.js, createImageDocument1, error: " + error.message)
        return null
      }
    } catch (error) {
      sendAlert("Create.js, createImageDocument2, error: " + error.message)
      return null
    }
  }

  useEffect(() => {
    setCreateImageFunction(
      () => () => createImageDocument(user, creditCost, prompt, setError)
    )
  }, [user, creditCost, prompt, setError])

  return (
    <div className="w-full pl-3 pr-1 py-2 pb-3 relative md:h-[calc(100vh-138px)] md:overflow-y-auto md:max-w-xs md:pr-3 border-neutral-700">
      <p className="font-bold mt-6 mb-2">Prompt</p>
      <textarea
        id="prompt"
        type="text"
        rows="3"
        className="bg-neutral-800 w-full border border-neutral-700 text-white text-sm rounded-lg block p-2.5"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
      />
      {/* 
      <div>
        <div>
          <div className="flex items-center">
            <p className="font-bold my-2 mr-2 mt-2">Amount images</p>
          </div>
          <select
            className="bg-neutral-800 w-full border border-neutral-700 text-white text-sm rounded-lg block p-2.5"
            value={style}
            onChange={(event) => setStyle(event.target.value)}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="4">4</option>
            <option value="8">8</option>
            <option value="16">16</option>

          </select>
        </div>
      </div> */}

      {prompt && (
        <div className="flex flex-col items-center justify-center my-4 py-1 bg-neutral-800 border border-neutral-700 rounded-lg text-sm text-neutral-400">
          <div className="text-center">
            {creditCost ? <p>Will cost {creditCost} tokens</p> : null}
          </div>
        </div>
      )}

      {error && (
        <div
          className="flex items-center p-4 mb-4 text-sm border rounded-lg bg-gray-800 text-red-400 border-red-800"
          role="alert"
        >
          <div> {error}</div>
        </div>
      )}
    </div>
  )
}
