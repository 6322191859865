import React, { useState } from "react"
import { firestore } from "../../firebase"
import Popup from "../Sign/Popup"

import { useAuth } from "../../context/AuthContext"
import { IconCopy, IconView, IconNoView } from "../../components/Icons"

import { sendAlert } from "../../utils/sendalert"

export default function Docs() {
  const { user } = useAuth()
  const [showPopup, setShowPopup] = useState(false)

  const generateKey = () => {
    let key = ""
    while (key.length < 40) {
      key += Math.random().toString(36).substr(2)
    }
    return key.substr(0, 40)
  }

  const createKey = async (keyName = "api_key") => {
    try {
      const randomKey = generateKey()
      await firestore
        .collection("users")
        .doc(user.id)
        .update({
          [keyName]:
            keyName === "comfyui_key" ? "cu_" + randomKey : "ap_" + randomKey,
        })
    } catch (error) {
      sendAlert(`API.js, error creating the key:` + error)
    }
  }

  const deleteKey = async (keyName = "api_key") => {
    try {
      await firestore
        .collection("users")
        .doc(user.id)
        .update({
          [keyName]: null,
        })
    } catch (error) {
      sendAlert(`API.js, error deleting the key:` + error)
    }
  }

  const [language, setLanguage] = React.useState("curl")
  const [showapikey, setShowapikey] = React.useState(false)
  const [copiedToken, setCopiedToken] = React.useState(false)
  const [copied, setCopied] = React.useState(false)

  const handleCopyToken = (text) => {
    copyToClipboard(text)
    setCopiedToken(true)
    setTimeout(() => setCopiedToken(false), 2000)
  }

  const handleCopy = (text) => {
    copyToClipboard(text)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  function copyToClipboard(text) {
    const dummy = document.createElement("textarea")
    dummy.style.position = "absolute"
    dummy.style.left = "-9999px"
    document.body.appendChild(dummy)
    dummy.value = text
    dummy.select()
    document.execCommand("copy")
    document.body.removeChild(dummy)
  }

  return (
    <div className="w-full flex flex-col items-center pt-12 mb-28 lg:pt-18 bg-bg-color text-white flex-grow">
      <div className="w-10/12 2xl:w-8/12 overflow-x-auto flex flex-col">
        <div>
          <div className="flex flex-row">
            {/* <div
          className={`${
            language === "curl" && "bg-blue-500 text-white"
          } px-4 py-1 rounded-2xl hover:bg-blue-800 cursor-pointer mr-2`}
          onClick={() => setLanguage("curl")}
        >
          curl
        </div> */}
            {/* <div
          className={`${
            language === "python" && "bg-black text-white"
          } px-4 py-1 rounded-2xl hover:bg-gray-400 cursor-pointer mr-2`}
          onClick={() => setLanguage("python")}
        >
          Python
        </div>
        <div
          className={`${
            language === "nodejs" && "bg-black text-white"
          } px-4 py-1 rounded-2xl hover:bg-gray-400 cursor-pointer mr-2`}
          onClick={() => setLanguage("nodejs")}
        >
          Node.js
        </div> */}
          </div>
          {/* <div>
            <p className="text-2xl font-bold mt-8 mb-4">ComfyUI Token</p>

            <div className="my-4 text-sm bg-neutral-800 p-4 relative">
              <pre>
                {user?.comfyui_key
                  ? showapikey
                    ? user?.comfyui_key
                    : user?.comfyui_key?.slice(0, 6) +
                      "**********************************"
                  : "Create a token first"}
              </pre>

              <button
                onClick={() => setShowapikey((showapikey) => !showapikey)}
                className="absolute top-2 right-14 text-xs bg-white text-gray-700 hover:text-gray-500 p-1 rounded border border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {showapikey ? <IconNoView /> : <IconView />}
              </button>
              <button
                onClick={() => handleCopyToken(user.comfyui_key)}
                className="absolute top-2 right-2 text-xs bg-white text-gray-700 hover:text-gray-500 p-1 rounded border border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {copiedToken && (
                  <span className="absolute z-10 top-full right-0 mt-2 bg-black text-white py-1 px-2 rounded">
                    Copied!
                  </span>
                )}
                <IconCopy />
              </button>
            </div>

            {user && user?.comfyui_key ? (
              <button
                onClick={() => deleteKey("comfyui_key")}
                className="h-12 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-800"
              >
                Delete
              </button>
            ) : (
              <button
                onClick={
                  user
                    ? () => createKey("comfyui_key")
                    : () => setShowPopup(true)
                }
                className="h-12 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-800 focus:outline-none focus:shadow-outline-red"
              >
                Create Token
              </button>
            )}
          </div> */}
          <div>
            <p className="text-2xl font-bold mt-16 mb-4">API Token</p>

            <div className="my-4 text-sm bg-neutral-800 p-4 relative">
              <pre>
                {user?.api_key
                  ? showapikey
                    ? user?.api_key
                    : user?.api_key?.slice(0, 6) +
                      "**********************************"
                  : "Create a token first"}
              </pre>

              <button
                onClick={() => setShowapikey((showapikey) => !showapikey)}
                className="absolute top-2 right-14 text-xs bg-white text-gray-700 hover:text-gray-500 p-1 rounded border border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {showapikey ? <IconNoView /> : <IconView />}
              </button>
              <button
                onClick={() => handleCopyToken(user.api_key)}
                className="absolute top-2 right-2 text-xs bg-white text-gray-700 hover:text-gray-500 p-1 rounded border border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {copiedToken && (
                  <span className="absolute z-10 top-full right-0 mt-2 bg-black text-white py-1 px-2 rounded">
                    Copied!
                  </span>
                )}
                <IconCopy />
              </button>
            </div>

            {user && user?.api_key ? (
              <button
                onClick={() => deleteKey("api_key")}
                className="h-12 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-800"
              >
                Delete
              </button>
            ) : (
              <button
                onClick={
                  user ? () => createKey("api_key") : () => setShowPopup(true)
                }
                className="h-12 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-800 focus:outline-none focus:shadow-outline-red"
              >
                Create Token
              </button>
            )}
          </div>

          <p className="text-2xl font-bold mt-8 mb-4">Request</p>

          <div className="my-4 text-sm bg-neutral-800 p-4 relative">
            {language === "curl" && (
              <>
                <pre>
                  {`curl -s -X POST \\
  "https://api.clarityai.co/v1/upscale"
  -H "Content-Type: application/json" \\
  -H "Authorization: Bearer ${
    showapikey ? user?.api_key : user?.api_key?.slice(0, 6) + "**********"
  }" \

  -d '{
        "image": "https://website.com/image.png",
        "creativity": 0,
        "resemblance": 0,
        "dynamic": 0,
        "fractality": 0,
        "scale_factor": 2,
        "style": "default",
        "prompt": "",
        "webhook": "https://webhook.site/123"
      }'`}
                </pre>
                <button
                  onClick={() => setShowapikey((showapikey) => !showapikey)}
                  className="absolute top-2 right-14 text-xs bg-white text-gray-700 hover:text-gray-500 p-1 rounded border border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {showapikey ? <IconNoView /> : <IconView />}
                </button>
                <button
                  onClick={() =>
                    handleCopy(`curl --request POST \
                --url https://api.clarityai.co/v1/upscale \
                --header 'Authorization: Bearer ${user?.api_key}' \
                --header 'Content-Type: application/json' \
                --data '{
                  "image": "https://website.com/image.png",
                  "creativity": 0,
                  "resemblance": 0,
                  "dynamic": 0,
                  "fractality": 0,
                  "scale_factor": 2,
                  "style": "default",
                  "prompt": "",
                  "webhook": "https://webhook.site/123"
              }'`)
                  }
                  className="absolute top-2 right-2 text-xs bg-white text-gray-700 hover:text-gray-500 p-1 rounded border border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {copied && (
                    <span className="absolute top-full right-0 mt-2 bg-black text-white py-1 px-2 rounded">
                      Copied!
                    </span>
                  )}
                  <IconCopy />
                </button>
              </>
            )}
            {language === "python" && (
              <>
                <pre>
                  {`import requests

url = "https://api.clarityai.co/v1/predictions"

headers = {
  "Content-Type": "application/json",
  "Authorization": "Token $"
}

payload = {
    "version": "SZnjWmepP7GHGNMh6oOD",
    "input": {
      prompt: 'fashion model, wearing down winter jacket, film grain, Fujifilm XT3',
      width: 512,
      height: 768,
      upscale: true,
    },
    "webhook": "https://your-webhook-url.com"
}

response = requests.request("POST", url, json=payload, headers=headers)

print(response.text)`}
                </pre>
                <button
                  onClick={() =>
                    handleCopy(`import requests

                  url = "https://api..io/v1/predictions"
                  
                  headers = {
                    "Content-Type": "application/json",
                    "Authorization": "Token $"
                  }
                  
                  payload = {
                      "version": "SZnjWmepP7GHGNMh6oOD",
                      "input": {
                        prompt: 'fashion model, wearing down winter jacket, film grain, Fujifilm XT3',
                        width: 512,
                        height: 768,
                        upscale: true,
                      },
                      "webhook": "https://your-webhook-url.com"
                  }
                  
                  response = requests.request("POST", url, json=payload, headers=headers)
                  
                  print(response.text)`)
                  }
                  className="absolute top-2 right-2 text-xs bg-white text-gray-700 hover:text-gray-500 p-1 rounded border border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {copied && (
                    <span className="absolute top-full right-0 mt-2 bg-black text-white py-1 px-2 rounded">
                      Copied!
                    </span>
                  )}
                  <IconCopy />
                </button>
              </>
            )}
            {language === "nodejs" && (
              <>
                <pre>
                  {`var axios = require("axios").default;

var options = {
  method: 'POST',
  url: 'https://api..io/v1/predictions',
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Token $'
  },
  data: {
    version: 'SZnjWmepP7GHGNMh6oOD',
    input: {
      prompt: 'fashion model, wearing down winter jacket, film grain, Fujifilm XT3',
      width: 512,
      height: 768,
      upscale: true,
    },
    webhook: 'https://your-webhook-url.com'
  }
};

axios.request(options).then(function (response) {
  console.log(response.data);
}).catch(function (error) {
  console.error(error);
});`}
                </pre>
                <button
                  onClick={() =>
                    handleCopy(`var axios = require("axios").default;

                  var options = {
                    method: 'POST',
                    url: 'https://api..io/v1/predictions',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: 'Token $'
                    },
                    data: {
                      version: 'SZnjWmepP7GHGNMh6oOD',
                      input: {
                        prompt: 'fashion model, wearing down winter jacket, film grain, Fujifilm XT3',
                        width: 512,
                        height: 768,
                        upscale: true,
                      },
                      webhook: 'https://your-webhook-url.com'
                    }
                  };
                  
                  axios.request(options).then(function (response) {
                    console.log(response.data);
                  }).catch(function (error) {
                    console.error(error);
                  });`)
                  }
                  className="absolute top-2 right-2 text-xs bg-white text-gray-700 hover:text-gray-500 p-1 rounded border border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {copied && (
                    <span className="absolute top-full right-0 mt-2 bg-black text-white py-1 px-2 rounded">
                      Copied!
                    </span>
                  )}
                  <IconCopy />
                </button>
              </>
            )}
          </div>
        </div>

        <p className="text-2xl font-bold mt-8 mb-4">Webhook (optional)</p>
        <span>
          When your image is ready, Clarity AI can send you a webhook at the URL
          you have provided in the 'webhook' parameter. If you not provide a
          webhook you will recieve a response with the image URL.
        </span>
        <p className="text-2xl font-bold mt-8 mb-4">Inputs</p>
        <span>
          The input parameters are the same as in the app. Provide a link to the
          image for "image". Creativity, resemblance, dynamic, and fractality
          accept a value between -10 and 10. Style accepts "default",
          "portrait", and "anime". Prompt is a string.
        </span>
      </div>
      <Popup show={showPopup} onClose={() => setShowPopup(false)} />
    </div>
  )
}
