import { telegram } from "./telegram"
import axios from "axios"

export const sendAlert = async (message) => {
  try {
    const response = await axios.post("https://api.sendalert.io/v1/alert", {
      key: "XYZ_meiner",
      project: "Clarity AI",
      mode: "error",
      text: `frontend, ${message}`,
    })

    return response.data
  } catch (error) {
    console.error("Error sending alert:", error.message)
    telegram(
      "error in sendAlert in clarity frontend, message: " +
        message +
        ", error message: " +
        error.message
    )
    throw error
  }
}
