import * as AWS from "aws-sdk"
import { sendAlert } from "../utils/sendalert"

async function convertToJPG(file) {
  let logMessages = [`Starting convertToJPG for file: ${file.name}`]

  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = async (event) => {
      logMessages.push("FileReader loaded")
      const img = new Image()
      img.onload = async () => {
        logMessages.push(
          `Image loaded, dimensions: ${img.width} x ${img.height}`
        )
        const canvas = document.createElement("canvas")
        canvas.width = img.width
        canvas.height = img.height
        const ctx = canvas.getContext("2d")
        if (!ctx) {
          const errorMsg = "Failed to get canvas context"
          sendAlert(logMessages.join("\n") + "\n" + errorMsg)
          reject(new Error(errorMsg))
          return
        }
        ctx.drawImage(img, 0, 0)
        logMessages.push("Image drawn to canvas")
        try {
          const blob = await new Promise((resolve, reject) => {
            canvas.toBlob(
              (blob) => {
                if (blob) {
                  logMessages.push(`Blob created, size: ${blob.size}`)
                  resolve(blob)
                } else {
                  const errorMsg = "Failed to create blob"
                  sendAlert(logMessages.join("\n") + "\n" + errorMsg)
                  reject(new Error(errorMsg))
                }
              },
              "image/jpeg",
              0.95
            )
          })
          logMessages.push("Conversion to JPG successful")
          resolve(blob)
        } catch (err) {
          sendAlert(
            logMessages.join("\n") +
              "\n" +
              `Error in blob creation: ${err.message}`
          )
          reject(err)
        }
      }
      img.onerror = (error) => {
        const errorMsg = `Error loading image: ${error}`
        sendAlert(logMessages.join("\n") + "\n" + errorMsg)
        reject(new Error("Failed to load image"))
      }
      img.src = event.target.result
    }
    reader.onerror = (error) => {
      const errorMsg = `FileReader error: ${error.message}`
      sendAlert(logMessages.join("\n") + "\n" + errorMsg)
      reject(error)
    }
    reader.readAsDataURL(file)
  })
}

export async function uploadToR2(file, convertToJPGOption = false) {
  const fileInfo = file
    ? {
        name: file.name,
        type: file.type,
        size: file.size,
      }
    : "File is undefined or null"

  const maxRetries = 3
  const retryDelay = 2000 // 2 seconds
  let finalError = null

  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      if (!file) {
        throw new Error("File is undefined or null")
      }

      if (!(file instanceof File)) {
        throw new Error(`Invalid file type. Expected File, got ${typeof file}`)
      }

      const accessKeyId = `3736df7c42164f7c799db4603ac8848f`
      const secretAccessKey = `798616681ca25c9d3ab935e957566b7c90ba1d363aebd2765fdf262d5f157861`
      const endpoint =
        "https://d181f16c5eadd7fb49486c2652a0f065.r2.cloudflarestorage.com"
      const bucketName = "clarity"

      const s3 = new AWS.S3({
        region: "auto",
        signatureVersion: "v4",
        credentials: new AWS.Credentials(accessKeyId, secretAccessKey),
        endpoint: new AWS.Endpoint(endpoint),
      })
      const uuid = require("uuid")
      let originalFilename = file.name.replace(/[^a-zA-Z0-9.-]/g, "_")
      let filename = `inputs/${uuid.v4()}-${originalFilename}`
      let fileBuffer = file
      let contentType = file.type

      if (convertToJPGOption) {
        try {
          fileBuffer = await convertToJPG(file)
          contentType = "image/jpeg"
          filename = filename.replace(/\.[^/.]+$/, ".jpg") // Change the file extension to .jpg
        } catch (error) {
          console.warn("Error while converting to JPG:", error)
          sendAlert(
            "convertToJPG failed, uploading original file: " + error.message
          )
          // Fallback to original file
          fileBuffer = file
          contentType = file.type
        }
      }

      const params = {
        Bucket: bucketName,
        Key: filename,
        Body: fileBuffer,
        ContentType: contentType,
        ACL: "public-read",
      }

      await s3.upload(params).promise()

      const link = "https://r2.clarityai.co/" + filename

      return link
    } catch (error) {
      finalError = error
      console.error(`Attempt ${attempt}/${maxRetries} failed:`, error.message)

      if (attempt < maxRetries) {
        await new Promise((resolve) => setTimeout(resolve, retryDelay))
      }
    }
  }

  // Only send Telegram message if all attempts have failed
  if (finalError) {
    const errorMessage = `Upload failed after ${maxRetries} attempts. Last error: ${
      finalError.message
    }. File info: ${JSON.stringify(
      fileInfo
    )}. ConvertToJPG: ${convertToJPGOption}. Browser: ${
      navigator.userAgent
    }. Online: ${navigator.onLine}`
    console.error(errorMessage)
    sendAlert(errorMessage)
  }

  throw new Error("Upload failed after multiple attempts")
}
